<template>
    <div class="mainbody download">
        <a href="javascript:void(0);" class="scrollT" ref="load"></a>
        <div class="sub-banner" v-if="bannerList.length > 0" :style="{'backgroundImage':'url('+ bannerList[0].pic +')'}">
			<div class="container">
				<div class="main">
					<h2 class="wow fadeInDown">Download center</h2>
				</div>
			</div>
		</div>
        <div class="load-main">
            <div class="container">
                <ul class="nav wow fadeInLeft">
                    <li v-for="item in cateList" :key="item.id">
                        <router-link :to="`/download?pid=${item.id}&p=1`" 
                            class="link"
                            :class="parId === item.id ? 'active':''" 
                            v-if="item.child.length === 0">
                            {{ item.name }}
                        </router-link>
                        <div class="box" v-else>
                            <div class="title">{{ item.name }}</div>
                            <router-link :to="`/download?pid=${item.id}&cid=${subItem.id}&p=1`"
                                :class="conditionObj.cid === subItem.id ? 'active':''" 
                                v-for="subItem in item.child" 
                                :key="subItem.id">
                                <i></i>{{ subItem.name }}
                            </router-link>
                        </div>
                    </li>  
                </ul>
                <div class="load-right">
                    <div class="download-list">
                    <div class="title wow fadeInDown">{{ fullName }}</div>
                        <ul class="clearfix">
                            <li class="wow fadeInUp" v-for="(loadItem,index) in loadList" :key="loadItem.id" :data-wow-delay="(index + 1)/5 + 's'">
                                <a class="view">
                                    <img src="../../assets/img/default.jpg" alt="" v-if="loadItem.pic === 'http://www.dmegcsolar.com'">
                                    <img :src="loadItem.pic" v-else alt="">
                                </a>
                                <div class="main">
                                    <!-- <div class="name">{{ loadItem.title }}</div> -->
                                    <a :href="loadItem.files" class="name" target="_blank">{{ loadItem.title }}</a>
                                    <a :href="loadItem.files" class="preview" target="_blank"></a>
                                    <a :href="loadItem.files" class="load" target="_blank" :download="loadItem.title"></a>
                                </div>
                                <!-- <div class="list">
                                    <a v-if="loadItem.files" :href="loadItem.files" target="_blank" :download="loadItem.title">EN <i></i></a>
                                    <a v-if="loadItem.de_file" :href="loadItem.de_file" target="_blank" :download="loadItem.title">DE <i></i></a>
                                    <a v-if="loadItem.fr_file" :href="loadItem.fr_file" target="_blank" :download="loadItem.title">FR <i></i></a>
                                    <a v-if="loadItem.es_file" :href="loadItem.es_file" target="_blank" :download="loadItem.title">ES <i></i></a>
                                    <a v-if="loadItem.nl_file" :href="loadItem.nl_file" target="_blank" :download="loadItem.title">NL <i></i></a>
                                    <a v-if="loadItem.it_file" :href="loadItem.it_file" target="_blank" :download="loadItem.title">IT <i></i></a>
                                </div> -->
                            </li>
                        </ul>
                    </div>
                    <el-pagination class="wow fadeInRight"
                        v-if="total >= 9"
                        layout="prev, pager, next"
                        :page-size="conditionObj.rows"
                        :current-page="conditionObj.p"
                        :total="total"
                        @current-change="changePage">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { Pagination } from 'element-ui'
    Vue.use( Pagination )
    import { WOW } from 'wowjs'
    import { getLoadCateList,getLoadList, getBannerList } from '../../request/api'
    export default {
        name:'Download',
        metaInfo:{
            title:"DMEGC Solar-download",
            meta: [{                 // set meta
                name: 'keyWords',
                content: 'DMEGC Solar'
            },{
                name:'description',
                content:'DMEGC Solar'
            }]
        },
        props: [ 'pid', 'cid', 'p' ],
        data(){
            return {
                conditionObj:{
                    cid:'',
                    rows:9,
                    p:1
                },
                parId: '',
                total: 0,
                cateList: [],
                loadList: [],
                bannerList: []
            }
        },
        computed:{
            fullName() {
                
                let name = '';

                if( this.cateList.length > 0){
                    
                    if( this.parId === this.conditionObj.cid ) {

                        const num1 = this.cateList.findIndex( item => item.id === this.parId )

                        if(num1 >= 0){

                            name = this.cateList[num1].name
                        }
                        
                    }else{

                        const num1 = this.cateList.findIndex( item => item.id === this.parId )
                        const num2 = this.cateList[num1].child.findIndex( item => item.id === this.conditionObj.cid)

                        if(num1 >=0 && num2 >=0 ){

                            name = this.cateList[num1].name + '-' + this.cateList[num1].child[num2].name
                        }
                        
                    }
                }
                
                return name
            }
        },
        watch:{
            $route:function(){

                if( this.cid === undefined ){

                    this.conditionObj.p = parseInt( this.p)
                    this.conditionObj.cid = this.pid
                } else {

                    this.conditionObj.p = parseInt( this.p )
                    this.conditionObj.cid = this.cid
                }
                this.parId = this.pid
                this.showLoadData()
            }
        },
        mounted(){
            getBannerList({cate:'download'}).then( res => {

                this.bannerList = res.data
            })
            this.intLoad();
        },
        methods: {
            
            async intLoad() {

                await getLoadCateList().then( res => {

                    this.cateList = res.data
                })


                if( this.cateList[0].child.length > 0){

                    this.parId = this.cateList[0].id
                    this.conditionObj.cid =  this.cateList[0].child[0].id
                } else {

                    this.parId = this.cateList[0].id
                    this.conditionObj.cid = this.cateList[0].id
                } 
                this.conditionObj.p = 1
                  
                await this.showLoadData();
            },
            showLoadData() {

                getLoadList( this.conditionObj ).then( res => {

                    this.loadList = res.data.downList
                    this.total = parseInt( res.data.totals )
                    this.$nextTick(() => {
               
                        const wow = new WOW({
                            animateClass: 'animated',
                            offset: 100
                        })
                        wow.init(); 
                        this.$refs.load.scrollIntoView();
                    })
                })
            },
            changePage(val){

                if(this.parId === this.conditionObj.cid ) {

                    this.$router.push({
                        name: 'load',
                        query:{
                            pid:this.parId,
                            p:val
                        }
                    })
                }else{

                    this.$router.push({
                        name: 'load',
                        query:{
                            pid: this.parId,
                            cid: this.conditionObj.cid,
                            p:val
                        }
                    })
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.download{
    position: relative;
}
.load-main{
    padding: 4.1667vw 0;
    .container{
        display: flex;
        justify-content: space-between;
    }
    .nav{
        width: 13.5417vw;
        li{
            border-bottom: 1px solid #CCD2DF;
            padding: 1.0417vw 0;
            &:last-child{
                border-bottom: none;
            }
            a{
                display: flex;
                font-size: 0.8333vw;
                line-height: 1.0417vw;
                color: rgba(0,0,0,0.6);
                margin-top: 0.625vw;
                align-items: center;
                i{
                    display: block;
                    width: 0.8333vw;
                    height: 0.8333vw;
                    background: url('../../assets/images/check.png') no-repeat;
                    background-size: cover;
                    margin-right: 0.4167vw;
                }
                &:hover{
                    color: #E60011;
                }
            }
            .title{
                font-size: 0.8333vw;
                line-height: 1.0417vw;
                color: rgba(0,0,0,0.8);
                font-weight: bold;
            }
            .link{
                display: block;
                font-weight: bold;
                color: rgba(0,0,0,0.8);
                margin-top: 0;
            }
            .active{
                color: #E60011;
                i{
                    background-image: url('../../assets/images/checked.png');
                }
            }
        }
    }
    .load-right{
        width: 64.5833vw;
        .title{
            font-size: 1.4583vw;
            line-height: 1.4583vw;
            color: rgba(0,0,0,0.8);
            margin-top: 1.0417vw;
        }
        .download-list{
            box-sizing: border-box;
            padding-left: 3.125vw;
            position: relative;
            &::before{
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 1px;
                height: calc(100% - 1.0417vw);
                background: #CCD2DF;
            }
            ul{
                margin-top: 1.6667vw;
            }
            li{
                float: left;
                width: 19.7917vw;
                height: 16.1667vw; 
                background: #EEEFF1;
                margin: 0 0.9375vw 1.0417vw 0;
                &:nth-child(3n){
                    margin-right: 0;
                }
            }
            .view{
                display: flex;
                box-sizing: border-box;
                height: 12.5vw;
                border-bottom: 1px solid #CCD2DF;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                position: relative;
                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    border:2px solid #E60011;
                    border-bottom: none;
                    display: none;
                }
                img{
                    display: block;
                    max-width: 92%;
                    max-height: 92%;
                }
            }
            .main{
                display: flex;
                box-sizing: border-box;
                height: 4.1667vw;
                padding: 0.7813vw 1.0833vw 0;
                justify-content: space-between;
                .name{
                    font-size: 0.8333vw;
                    line-height: 1.25vw;
                    color: rgba(0,0,0,0.8);
                    width: 13.9583vw;
                }
                .load{
                    display: block;
                    width: 1.25vw;
                    height: 1.25vw;
                    background: url('../../assets/images/dwonload.png') no-repeat;
                    background-size: 1.25vw 2.5vw;
                }
                .preview{
                    display: block;
                    width: 1.25vw;
                    height: 1.25vw;
                    background: url('../../assets/images/view.png') no-repeat;
                    background-size: cover;
                }
            }
            .list{
                display: flex;
                flex-wrap: wrap;
                margin-top: 0.5208vw;
                padding: 0 1.0833vw;
                a{
                    display: flex;
                    align-items: center;
                    font-size: 0.8333vw;
                    line-height: 1.75;
                    padding: 0 0.5208vw;
                    background: #fff;
                    color: #1a3a56;
                    margin:0 0.5208vw 0.5208vw 0;
                    i{
                        display: block;
                        width: 0.8333vw;
                        height: 0.8333vw;
                        background: url('../../assets/images/dwonload.png') no-repeat;
                        background-size: 0.8333vw 1.6666vw;
                        margin-left: 0.5208vw;
                    }
                }
            }
        }
        .el-pagination{
            padding: 0;
            text-align: right;
            /deep/.el-pager{
                .active{
                    color: #E60011;
                }
            }
        }
    }
}

@media screen and (max-width: 750px) {
    .load-main{
        padding: .6rem 0 .8rem;
        .container{
            display: block;
        }
        .nav{
            width: 100%;
            li{
                padding: .3rem 0;
                
                a{
                    font-size: .24rem;
                    line-height: .4rem;
                    margin-top: .2rem;
                    i{
                        width: .24rem;
                        height: .24rem;
                        margin-right: .1rem;
                    }
                }
                .link,
                .title{
                    font-size: .28rem;
                    line-height: .48rem;
                }
                &:last-child{
                    border-bottom: 1px solid #CCD2DF;
                }
            }
        }
        .load-right{
            width: 100%;
            padding-top: .8rem;
            .download-list{
                padding-left: 0;
                &::before{
                    display: none;
                }
                ul{
                    margin-top: .6rem;
                }
                li{
                    width: 48%;
                    height: auto;
                    margin: 0 4% .4rem 0 !important;
                    &:nth-child(2n){
                        margin-right: 0 !important;
                    }
                    .view{
                        height: 2.3rem;
                    }
                }
                .main{
                    height: 1.2rem;
                    padding: .3rem .2rem 0;
                    .name{
                        font-size: .26rem;
                        line-height: .44rem;
                        width: 100%;
                    }
                    .load{
                        display: none;
                    }
                    .preview{
                        display: none;
                    }
                }
                .list{
                    margin-top: .2rem;
                    padding: 0 .2rem;
                    a{
                        font-size: .24rem;
                        margin: 0 .2rem .2rem 0;
                        padding: 0 .1rem;
                        &:nth-child(3n){
                            margin-right: 0;
                        }
                        i{
                            width: .36rem;
                            height: .36rem;
                            background-size: .36rem .72rem;
                            margin-left: .1rem;
                        }
                    }
                }
            }
            .title{
                font-size: .3rem;
                line-height: .5rem;
            }
        }
    }
}
</style>